import React from 'react';
import { category1, category2, category3, stars } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const Comments = () => {

  const { t, i18n } = useTranslation(); 
  return ( 
     <div className="md:max-w-container md:mt-[154px] mt-[64px] gap-5 rounded-[24px] pt-10 md:pt-0 pb-10 md:pb-0 md:pl-[40px] bg-[#FB8C00] max-w-containerSm mx-auto md:flex items-center">
   <div className='md:w-[562px] text-white px-3 md:pl-0'>
  <p className='md:text-[46px] text-3xl font-medium leading-[55px] '>{t("Rəyinizlə daha yaxşıya doğru !")}</p>
  <p className='mt-[22px] md:text-xl'>{t("Müştərilərimizin rəyləri sizin üçün doğru seçim olduğumuzu sübut edir. Bizi seçənlərin xoş təcrübələrini burada görün. Onların sözləri bizim motivasiyamızdır!")}</p>
   </div>
   <div className='md:w-[615px] flex flex-col justify-center items-center mt-10 md:mt-0'>
  <div className='md:h-[174px] w-[90%] md:w-[527px] rounded-[21px] border border-[#388e3c] px-6 py-4 bg-[white]'>
 <div className='flex justify-between items-center'>
  <p className='text-xl'>{t("Əli Həsənov")}</p>
  <div>
    <img className='w-[80px] h-[16px]' src={stars}></img>
  </div>
 </div>
 <div className='mt-2'>
<p>{t("“Bu şirkət ilə əməkdaşlıq etmək çox rahat oldu. Vebsaytımı istədiyim kimi hazırladılar və dizaynı gözlədiyimdən də gözəl oldu. Komanda çox peşəkar və diqqətlidir. Hər sualımı cavablandırdılar və layihə boyu heç bir çətinlik yaşamadım. Çox təşəkkür edirəm!”")}</p>
 </div>
  </div>
  <div className='md:h-[174px] w-full mt-2 rounded-[21px] border border-[#388e3c] px-6 py-4 bg-[white]'>
 <div className='flex justify-between items-center'>
  <p className='text-xl'>{t("Nigar Məmmədli")}</p>
  <div>
    <img className='w-[80px] h-[16px]' src={stars}></img>
  </div>
 </div>
 <div className='mt-2'>
<p>{t("“Prosayt şirkəti ilə işləmək çox məmnuniyyətverici təcrübə oldu. Vebsaytımı istədiyim kimi tərtib etdilər və hər detala diqqət yetirdilər. Xidmətlərindən çox razı qaldım və nəticə mükəmməl oldu!”")}</p>
 </div>
  </div>
  <div className='md:h-[174px] w-[90%] md:w-[527px] mt-2 rounded-[21px] border border-[#388e3c] px-6 py-4 bg-[white]'>
 <div className='flex justify-between items-center'>
  <p className='text-xl'>{t("Ramin Səfərli")}</p>
  <div>
    <img className='w-[80px] h-[16px]' src={stars}></img>
  </div>
 </div>
 <div className='mt-2'>
<p>{t("“İşimiz üçün vebsayt hazırlamaq məqsədilə əlaqə saxladıq və gözlədiyimizdən daha yaxşı nəticə əldə etdik. Komanda çox yardımsevər və vaxtında xidmət göstərdi. Təklif etdikləri həll yolları biznesimizi bir addım irəli apardı. Təşəkkürlər!”")}</p>
 </div>
  </div>
   </div>
    </div>
  );
};

export default Comments;
