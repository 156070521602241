import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from "./Components/redux/store";
import "./index.css";
import App from "./App";
import { loadingGif } from "./assets/images";

// Video Loading Bileşeni

const Index = () => {

 
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
 