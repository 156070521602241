import React from 'react';
import { motion } from 'framer-motion';
import { hero1, hero2, dots, logoAbout } from '../../../assets/images'; 
import { useTranslation } from 'react-i18next';

const Carousel = () => {

    const { t, i18n } = useTranslation();
  return (
    <>
      <div className="h-[685px] relative hidden md:block">
        {/* Sol Alt Köşe Resmi (dots) */}
        <motion.img
          src={dots} 
          alt="Corner Image Left"
          className="absolute bottom-0 left-0 w-[165px] h-[165px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 1 }}
        />

        {/* Sağ Üst Köşe Resmi (dots) */}
        <motion.img
          src={dots}
          alt="Corner Image Right"
          className="absolute top-0 right-0 w-[165px] h-[165px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 1 }}
        />

        <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between relative">
          {/* Sol Bölüm */}
          <motion.div
            className="md:w-[610px] rounded-[24px] py-[131px] z-10 relative"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 1 }}
          >
            <p className="text-[#0e0e0d] text-[40px] font-bold font-['DM Sans'] leading-[70px]">
              {t("VEB SAYTINIZ UĞURUNUZUN AÇARIDIR !")}
            </p>
            <p className="text-[#0e0e0d] text-xl font-normal font-['DM Sans'] leading-[30px] mt-8">
              {t("Biznesiniz üçün ən keyfiyyətli vebsaytı qısa müddətdə hazırlayırıq !")}
            </p>
            <a href="/contact">
              <motion.button
                className="h-[51px] px-2 rounded-[12px] bg-[#FB8C00] text-white mt-[32px]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 1 }}
              >
                {t("Qiymət təklifi alın")}
              </motion.button>
            </a>
          </motion.div>

          {/* Sağ Bölüm */}
          <div className="relative flex md:w-[479px] rounded-[24px] mt-[50px] items-center">
            {/* dots fotoğrafı ilk görünür */}
            <motion.img
              className="absolute left-0 z-20 h-[480px] w-[480px] -translate-x-[150px]" // Dots resminin yerleşimi
              src={dots}
              alt="Dots"
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              transition={{ delay: 0.6, duration: 1 }}
            />

            {/* hero1 fotoğrafı dots'un üstüne geliyor */}
            <motion.img
              className="absolute left-0 z-20 h-[480px] w-[480px] -translate-x-[150px]" // hero1 dots'un üzerine gelir
              src={hero1}
              alt="Hero 1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.4, duration: 1 }}
            />

            {/* hero2 fotoğrafı */}
            <motion.img
              className="relative z-10 h-[585px] w-[392px] translate-x-[87px] rounded-2xl" // hero2 still below
              src={hero2}
              alt="Hero 2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8, duration: 1 }}
            />
          </div>
        </div>
      </div>
      <div className=" max-w-containerSm mx-auto mt-[64px] md:mt-[104px] justify-between md:hidden">
        <motion.div
          className="md:w-[745px] rounded-[24px] py-4 px-6"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6, duration: 1 }}
        >
          <p className="text-[#0e0e0d] text-3xl font-bold font-['DM Sans'] leading-[48px]">{t("VEB SAYTINIZ UĞURUNUZUN AÇARIDIR !")}</p>
          <p className='mt-[18px] md:text-[20px]'>{t("Biznesiniz üçün ən keyfiyyətli vebsaytı qısa müddətdə hazırlayırıq !")} </p>
          <a href='/contact'>
            <motion.button
              className='h-[51px] w-full rounded-[12px] bg-[#FB8C00] text-white mt-[32px]'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 1 }}
            >
              {t("Qiymət təklifi alın")}
            </motion.button>
          </a>
        </motion.div>
      </div>

      <div>
        <div className="relative flex rounded-[24px] mt-[50px] items-center md:hidden">
          {/* hero1 */}
          <motion.img
            className="absolute left-0 z-20 h-[265px] w-[265px] translate-x-[45px]"
            src={hero1}
            alt="Hero 1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 1 }}
          />
          {/* hero2 */}
          <motion.img
            className="relative z-10 h-[321px] w-[216px] translate-x-[157px] rounded-2xl"
            src={hero2}
            alt="Hero 2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.4, duration: 1 }}
          />
          {/* New image at the bottom-left */}
          <motion.img
            className="absolute bottom-0 left-0 h-[120px] w-[120px]"
            src={dots}  // Yeni fotoğrafın kaynağını burada belirtin
            alt="New Image"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.6, duration: 1 }}
          />
        </div>
      </div>
    </>
  );
};

export default Carousel;