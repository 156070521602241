import React, { useState } from 'react'
import { about, about1, about2, about3, contact, emailContact, facebook, facebookContact, icon1, icon2, icon3, icon4, icon5, icon6, instagram, instagramContact, instagramFooter, product1, product2, product3, product4 } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  phone,mail, sosial1, sosial2, sosial3, mailOrange, phoneOrange
} from '../../assets/images';


const Contact = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");
  const [clientWish, setclientWish] = useState("");



  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage, wish: clientWish };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.prosayt.az/prosayt/contact.php/", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      setclientWish('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };
  return ( 
    <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
                <p >{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`}/> {t("Əlaqə")}</p>
     <div className="md:flex justify-between  gap-10 mt-[24px] md:mt-[56px]">
     <div className='md:w-[745px] md:h-[563px] bg-[white] rounded-[24px] px-6 py-4 box-border '>
      <p className='text-3xl md:text-[46px] font-medium md:mt-6'>{t("Bir Sualınız Var?")} </p>
      <p className='text-3xl md:text-[46px] font-medium mb-8 md:mb-0 md:mt-6'>{t("Cavabımız Hazırdır")}</p>
      <form onSubmit={handleSubmitContact} className='md:mt-6'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
      <div>
        <input
        onChange={(event) => setclientName(event.target.value)}
        value={clientName}
        required
         placeholder={t("Ad, soyad *")} type="text" id="name" class="bg-[white] border border-neutral-200 mt-2 block w-full  rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3" />

      </div>

    
      <div>
        <input  onChange={(event) => setclientEmail(event.target.value)}
        value={clientEmail}
         required placeholder={t("Email *")} type="email" id="email" class="bg-[white] border border-neutral-200 mt-2 block w-full  rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3" ></input>
      </div>

      <div>
        <input onChange={(event) => setclientPhone(event.target.value)}
      value={clientPhone}
      required placeholder={t("Telefon *")} type="number" id="phone" class="bg-[white] border border-neutral-200 mt-2 block w-full  rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"></input>
      </div>
      </div>

   <div>
      <textarea
   id="message" rows="4" onChange={(event) => setclientWish(event.target.value)}
   value={clientWish} placeholder={t("Sizə necə kömək edə bilərik? *")} class="bg-[white] h-36 pl-3 pr-3 pt-3 block w-full border mt-6 border-[#dddddd] rounded-[5px] shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" ></textarea>
    
    </div>

    <div class="flex justify-end">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-[#FB8C00] rounded-xl justify-center items-center mt-6 gap-2.5 inline-flex text-[white]">{t("Göndər")}</button>
    </div>
  </form>
    </div>
    <div className='md:w-[387px] mt-10 md:mt-0'>
          <ul className='space-y-2 md:space-y-4 mt-5'>
          <li className="text-[#FB8C00] text-xl"><a href="mailto:info@prosayt.az" className="hover:underline flex"> <img className='h-[30px] w-[30px] mr-2' src={mailOrange}></img>{t("E-poçt")}</a></li>
          <li className="text-xl">info@prosayt.az</li>
            <li className="text-[#FB8C00] text-xl"><a href="tel:+994516834218" className="hover:underline flex"> <img className='h-[30px] w-[30px] mr-2' src={phoneOrange}></img>{t("Əlaqə nömrəsi")}</a></li>
            <li className="text-xl">+994 50 683 42 18</li>


          </ul>
          <div className='flex gap-3 mt-[20px] md:mt-[65px]'>
  <div>
  <img className='w-10 h-10' src={sosial1}></img>
  </div>
  <div>
  <img className='w-10 h-10' src={sosial2}></img>
  </div>
  <div>
  <img className='w-10 h-10' src={sosial3}></img>
  </div>
</div>
        </div>
     </div>
     <div className='mt-10 md:mt-20'>
     <iframe 
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.859337037306!2d49.8414952!3d40.3763893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dc8c7cb8537%3A0xae4ef10fc5fafbf0!2sProsayt.az!5e0!3m2!1saz!2saz!4v1728198206019!5m2!1saz!2saz" 
    className="w-full h-48 sm:h-96 md:h-64 lg:h-72 xl:h-96 max-w-full" 
    allowFullScreen 
    loading="lazy" 
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
     </div>
</div>
  )
}

export default Contact 