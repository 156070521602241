import React from 'react'
import { about, about1, about2, about3, discovery1, discovery2, icon6, logoAbout, product1, product2, product3, product4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';


const About = () => {
   
  const { t, i18n } = useTranslation();
  return (
      <div className="md:max-w-container max-w-containerSm mx-auto mt-[64px] md:mt-[104px] md:flex justify-between">
   <div className='flex flex-col justify-center md:w-[479px] rounded-[24px]'>
<img className='rounded-xl' src={logoAbout}></img>
   </div>
   <div className='md:w-[745px] bg-[white] rounded-[24px] py-4 px-6'>
    <p className='md:text-[40px] md:leading-[50px] text-xl font-medium'>{t("IT Sektoru Lideri - PROSAYT MMC sizi qarşılayır !")}</p>
    <p className='mt-[18px] md:text-[20px]'>{t("Prosayt şirkəti olaraq, 7 illik təcrübəmizlə yüksək keyfiyyətli və sürətli vebsaytların hazırlanması sahəsində fəaliyyət göstəririk. Müxtəlif sahələr üzrə 200-dən çox uğurla tamamlanmış layihəmiz mövcuddur. Biz müştərilərimizə yalnız vebsayt yaradılması deyil, eyni zamanda domen və hosting satışı, korporativ poçt xidmətləri və texniki dəstək kimi geniş çeşidli xidmətlər də təklif edirik. Məqsədimiz, hər bir müştərimiz üçün fərdi ehtiyacları nəzərə alaraq, mükəmməl həllər təqdim etmək və onların onlayn uğurunu təmin etməkdir.")}</p>
   <a href='/why-us'>    <button className='h-[51px] w-full md:w-[164px] rounded-[12px] bg-[#FB8C00] text-white mt-[32px]'>{t("Daha ətraflı")}</button></a>
   </div>
  </div>
  )
}

export default About