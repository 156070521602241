import React from 'react';
import { footerLogo, icon1, icon2, icon6, instagramFooter, logo, mail, phone, sosial1, sosial2, sosial3, whatsappFooter } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t, i18n } = useTranslation();

  return (
    <footer className="bg-[white] mt-[64px] md:mt-[104px]">
      <div className="max-w-containerSm md:max-w-container mx-auto md:flex justify-between py-[50px] md:py-[80px]">
        <div className='md:w-[254px] '>
         <img className='w-[254px]' src={logo}></img>
          <p className='text-[17px] mt-[20px] md:mt-[65px]'>{t("Biznesinizi bizimlə inkişaf etdirin !")}</p>
<div className='flex gap-3 mt-[20px] md:mt-[65px]'>
  <div>
  <a href='https://api.whatsapp.com/send/?phone=994516834218&text&type=phone_number&app_absent=0'><img className='w-10 h-10' src={sosial1}></img></a>
  </div>
  <div>
  <a href='https://www.instagram.com/prosayt.az'><img className='w-10 h-10' src={sosial2}></img></a>
  </div>
  <div>
  <a href='https://www.linkedin.com/company/prosaytmmc'><img className='w-10 h-10' src={sosial3}></img></a>
  </div>
</div> 
        </div>  
        <div className='md:w-[387px] mt-10 md:mt-0'>
          <h4 className="text-[28px] font-medium text-[black]">{t("Sürətli keçid")}</h4>
          <div className="mt-5 bg-[#E5E5E5] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a href="/" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Ana səhifə")}</a></li>
                        <li><a href="/why-us" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Niyə biz?")}</a></li>
                          <li><a href="/services" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Xidmətlərimiz")}</a></li>
                                        <li><a href="/portfolio" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Portfolio")}</a></li>
                                        <li><a href="/faq" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("FAQ")}</a></li>
                                        <li><a href="/blogs" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Bloq")}</a></li>
                                        <li><a href="/contact" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Əlaqə")}</a></li>
          </ul>
        </div>
        <div className='md:w-[387px] mt-10 md:mt-0'>
          <h4 className="text-[28px] font-medium text-[black]">{t("Əlaqə")}</h4>
          <div className="mt-5 bg-[#E5E5E5] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a href="tel:+994516834218" className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={phone}></img>{t("Əlaqə nömrəsi")}</a></li>
            <li>+994 51 683 42 18</li>
                <li><a href="mailto:info@prosayt.az" className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={mail}></img>{t("E-poçt")}</a></li>
                <li>info@prosayt.az</li>

          </ul>
        </div>
      </div>
      <div className='bg-[#E5E5E5] h-[1px] max-w-containerSm md:max-w-container mx-auto'></div>
      <div className="max-w-containerSm md:max-w-container md:flex justify-between mx-auto py-4 text-sm">
        <p>Prosayt.az © {t("Bütün hüquqlar qorunur.")}</p>
        <a href='https://www.prosayt.az'><p>{t("Sayt hazırlandı")}: Prosayt.az</p></a>
      </div>
    </footer>
  );
};

export default Footer;
