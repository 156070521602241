import React from "react";
import './Carousel.css';
import { useTranslation } from "react-i18next";
import { about, why1, why2, why3, why4, why5, why6, why7, why8, why9 } from "../../../assets/images/index";


  const opportunitiesData = [
    { id: 1, image: why1,  title: "Peşəkar Komanda", description: "Təcrübəli və ixtisaslı mütəxəssislərlə layihələrinizi reallaşdırırıq." },
    { id: 2, image: why2, title: "Kreativ Yanaşma", description: "Hər layihəyə fərqli və yenilikçi baxış bucağı gətiririk." },
    { id: 3, image: why3, title: "Texniki Dəstək", description: "Xidmət sonrası tam texniki dəstək ilə hər zaman yanınızdayıq." },
    { id: 4, image: why4, title: "Keyfiyyət və Etibarlılıq", description: "Yüksək keyfiyyətli və etibarlı həllər təqdim edirik." },
    { id: 5, image: why5, title: "Münasib Qiymətlər", description: "Büdcənizə uyğun və keyfiyyətli xidmətlər təklif edirik." },,
    { id: 6, image: why6, title: "Müasir Texnologiyalar", description: "Layihələrinizdə ən son texnologiyalardan istifadə edirik." },
  ];

const Opportunities = () => {


    const { t, i18n } = useTranslation();
  return (
    <div className="max-w-containerSm md:max-w-container mx-auto mt-[64px] md:mt-[104px]">
      <div className="flex items-center justify-between">
        <p className="md:text-[46px] text-3xl font-medium">{t("Niyə biz ?")}</p>
        <a href='/why-us'>
          <button className='h-[51px] w-[164px] rounded-[12px] bg-[#FB8C00] text-white md:block hidden'>{t("Hamısına bax")}</button>
        </a>
      </div>
      <div className="grid md:grid-cols-3 mt-10 gap-10">
        {opportunitiesData.map((item) => (
          <div key={item.id} className="border-[1px] border-[#388E3C] rounded-[20px]">
            <img className="h-[194px] w-full rounded-t-[20px]" src={item.image} alt="Opportunity" />
            <div className="px-5">
              <p className="mt-[20px] text-2xl md:text-[24px] font-medium">{t(item.title)}</p>
              <p className="mt-[12px] mb-[20px]">{t(item.description)}</p>
            </div>
          </div>
        ))}
      </div>
      <a href='/why-us'>
          <button className='h-[51px] w-full rounded-[12px] bg-[#FB8C00] text-white md:hidden block mt-8'>Hamısına bax</button>
        </a>
    </div>
  );
};

export default Opportunities;
