import React, { useState } from 'react';
import {
  about,
  about1,
  about2,
  about3,
  care1,
  care10,
  care11,
  care2,
  care3,
  care4,
  care5,
  care6,
  care7,
  care8,
  care9,
  flag1,
  flag2,
  flag3,
  flag4,
  flag5,
  flag6,
  logoAbout,
  opportunity1,
  opportunity2,
  opportunity3,
  opportunity4,
  partners1,
  partners2,
  partners3,
  partners4,
  partners5,
  portfolio1,
  portfolio10,
  portfolio11,
  portfolio12,
  portfolio13,
  portfolio14,
  portfolio15,
  portfolio16,
  portfolio17,
  portfolio18,
  portfolio19,
  portfolio2,
  portfolio20,
  portfolio21,
  portfolio22,
  portfolio23,
  portfolio24,
  portfolio25,
  portfolio3,
  portfolio4,
  portfolio5,
  portfolio6,
  portfolio7,
  portfolio8,
  portfolio9,
  product1,
  product2,
  product3,
  product4,
  sertifikat1,
  sertifikat2,
  sertifikat3,
  sertifikat4,
  sertifikat5,
  sertifikat6,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  why1,
  why2,
  why3,
  why4,
  why5,
  why6,
  why7,
  why8,
  why9
} from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';



const portfolio = [
  { id: 1, image: portfolio1 },
  { id: 2, image: portfolio2 },
  { id: 3, image: portfolio3 },
  { id: 4, image: portfolio4 },
  { id: 5, image: portfolio5 },
  { id: 6, image: portfolio6 },
  { id: 7, image: portfolio7 },
  { id: 9, image: portfolio9 },
  { id: 10, image: portfolio10 },
  { id: 11, image: portfolio11 },
  { id: 12, image: portfolio12 },
  { id: 13, image: portfolio13 },
  { id: 14, image: portfolio14 },
  { id: 15, image: portfolio15 },
  { id: 16, image: portfolio16 },
  { id: 17, image: portfolio17 },
  { id: 18, image: portfolio18 },
  { id: 19, image: portfolio19 },
  { id: 20, image: portfolio20 },
  { id: 21, image: portfolio21 },
  { id: 22, image: portfolio22 },
  { id: 23, image: portfolio23 },
  { id: 24, image: portfolio24 },
  { id: 25, image: portfolio25 },



];

const Care = () => {
  const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showText, setShowText] = useState(false);
    const [showButton, setShowButton] = useState(false);
      const flags = [flag1, flag2, flag3, flag4, flag5, flag6];
      const partners = [partners1, partners2, partners3, partners4, partners5];


  return (

     <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
          <p >{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`}/> {t("Portfolio")}</p>
          <div>
          <div className="max-w-container mx-auto mt-[24px] md:mt-[56px] ">
      <div className="flex items-center justify-between">
        <p className="text-3xl md:text-[46px] font-medium mx-auto">{t("Portfolio")}</p>
      </div>
      <div className="grid md:grid-cols-4 grid-cols-1 mt-10 gap-10">
        {portfolio.map((item) => (
          <div key={item.id} className="rounded-[12px] w-full bg-white md:flex">
           <div>
           <img className=" rounded-[12px]" src={item.image} alt="Opportunity" />
           </div>
          </div>
        ))}
      </div>
    </div>
          </div>
         
        </div>
  );
};

export default Care;