import React, { useEffect, useState } from 'react';
import { flag1, flag2, flag3, flag4, flag5, flag6, partners1, partners2, partners3, partners4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Partners = () => {
  const { t, i18n } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showText, setShowText] = useState(false);
    const [showButton, setShowButton] = useState(false);

  // Bayraklar dizisi
  const partners = [
    { id: 1, image: partners1,  },
    { id: 2, image: partners2,},
    { id: 3, image: partners3, },
    { id: 4, image: partners4,},
  ];

    useEffect(() => {
      const textTimer = setTimeout(() => {
        setShowText(true);
      }, 500);
  
      const buttonTimer = setTimeout(() => {
        setShowButton(true);
      }, 1500);
  
      return () => {
        clearTimeout(textTimer);
        clearTimeout(buttonTimer);
      };
    }, [activeIndex]);
  

  return (
    <div className="md:max-w-container md:mt-[104px] mt-[64px] max-w-containerSm mx-auto">
      <p className="text-black text-3xl md:text-[46px] font-medium text-center mb-8 md:mb-0">
      {t("Partnyorlarımız")}
      </p>
      <div className="hidden mt-[56px] md:gap-[124px] md:flex">
                  {partners.map((flag, index) => (
                    <div key={index}  >
                      <img className="h-[80px] mx-auto mt-8 md:mx-0 md:mt-0" src={flag.image} alt={`Flag ${index + 1}`} />
                    </div>
                  ))}
                </div>
      <div className='block md:hidden'>
       <Swiper
           slidesPerView={1}
           spaceBetween={20}
           loop={true}
           autoplay={{
             delay: 4000,
             disableOnInteraction: false,
           }}
           pagination={{
             clickable: true,
           }}
           onSlideChange={(swiper) => {
             setShowText(false);
             setShowButton(false); 
             setActiveIndex(swiper.activeIndex);
           }}
           modules={[Autoplay, Pagination, Navigation]}
           className="mySwiper"
         >
           {partners.map((slide, index) => (
             <SwiperSlide key={index}>
             <a href={`/partners/${slide.id}`}>
             <div key={index} className='flex justify-center items-center'>
            <img  src={slide.image} alt={`Flag ${index + 1}`} />
          </div>
             </a>
             </SwiperSlide>
           ))}
         </Swiper>
      </div>
    </div>
  );
};

export default Partners;
