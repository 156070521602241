import React, { useState } from 'react';
import {
  about,
  about1,
  about2,
  about3,
  flag1,
  flag2,
  flag3,
  flag4,
  flag5,
  flag6,
  logoAbout,
  opportunity1,
  opportunity2,
  opportunity3,
  opportunity4,
  partners1,
  partners2,
  partners3,
  partners4,
  partners5,
  product1,
  product2,
  product3,
  product4,
  sertifikat1,
  sertifikat2,
  sertifikat3,
  sertifikat4,
  sertifikat5,
  sertifikat6,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  why1,
  why2,
  why3,
  why4,
  why5,
  why6,
  why7,
  why8,
  why9
} from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const opportunitiesData = [
  { id: 1, image: '🌐',  title: "Vebsaytların hazırlanması", description: "Profesional və sürətli vebsayt hazırlama xidmətləri" },
  { id: 2, image: '🖥️', title: "Domen və hostinq satışı", description: "Münasib qiymətə etibarlı domen və sürətli hostinq xidmətləri" },
  { id: 3, image: '⚙️', title: "Google SEO", description: "Google SEO ilə axtarış nəticələrində zirvəyə qalxın" },
  { id: 4, image: '🛠️', title: "Vebsaytlara texniki dəstək", description: "Vebsaytlar üçün 24/7 texniki dəstək xidmətləri" },
  { id: 5, image: '📧', title: "Korporativ poçt", description: "Güvənli və professional korporativ e-poçt həlləri" },
  { id: 6, image: '💳', title: "Ödəmə sistemlərinin inteqrasiyası", description: "Müxtəlif ödəmə sistemlərinin sürətli inteqrasiyası" },
];

const Services = () => {
  const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showText, setShowText] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const flags = [
      { id: 1, image: flag1,  },
      { id: 2, image: flag2,},
      { id: 3, image: flag3, },
      { id: 4, image: flag4,},
      { id: 5, image: flag5,},
      { id: 6, image: flag6, },
    ];
      const partners = [partners1, partners2, partners3, partners4];


  return (

     <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
          <p >{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`}/> {t("Xidmətlər")}</p>
          <div>
          <div className="max-w-container mx-auto mt-[24px] md:mt-[56px] hidden md:block">
      <div className="flex items-center justify-between ">
        <p className="text-[46px] font-medium text-center mx-auto">{t("Xidmətlərimiz")}</p>
      </div>
      <div className="grid grid-cols-3 mt-10 gap-10">
        {opportunitiesData.map((item) => (
          <div key={item.id} className="rounded-[20px] py-4 w-[400px] h-[220px] px-3 shadow bg-white">
            <div className="h-[50px] w-[50px] mx-auto text-4xl"> {item.image} </div>
            <div>
              <p className="mt-[12px] text-[22px] font-medium text-center">{t(item.title)}</p>
              <p className="mt-[12px] mb-[32px] text-center">
                {item.description.length > 80 ? item.description.slice(0,80) + '...' : t(item.description)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
     <div className="max-w-containerSm mx-auto mt-[64px] md:mt-[104px] block md:hidden">
     <p className="text-3xl font-medium text-center mb-8">{t("Xidmətlərimiz")}</p>
     <Swiper
      slidesPerView={1}
      spaceBetween={20}
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false); 
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {opportunitiesData.map((slide, index) => (
        <SwiperSlide key={index}>
       <div key={slide.id} className="rounded-[20px] py-4 px-3 shadow bg-white">
       <div className="h-[50px] w-[50px] mx-auto text-4xl"> {slide.image} </div>
            <div>
              <p className="mt-[12px] text-[22px] font-medium text-center">{t(slide.title)}</p>
              <p className="mt-[12px] mb-[32px] text-center">
                {slide.description.length > 60 ? slide.description.slice(0,60) + '...' : t(slide.description)}
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    <a href='/services'>
          <button className='h-[51px] w-full md:w-[164px] rounded-[12px] mt-8 bg-[#FB8C00] text-white md:block hidden'>{t("Hamısına bax")}</button>
        </a>
        </div>
          </div>
            <div className="md:max-w-container md:mt-[104px] mt-[64px] max-w-containerSm mx-auto">
                <p className="text-black text-3xl md:text-[46px] font-medium text-center mb-8 md:mb-0">
                {t("Partnyorlarımız")}
                </p>
                <div className="block mt-[56px] md:gap-[124px] md:flex">
                  {partners.map((flag, index) => (
                    <div key={index} >
                      <img className="h-[80px] mx-auto mt-8 md:mx-0 md:mt-0" src={flag} alt={`Flag ${index + 1}`} />
                    </div>
                  ))}
                </div>
              </div>
                {/* <div className="md:max-w-container md:mt-[104px] mt-[64px] max-w-containerSm mx-auto">
                    <p className="text-black text-3xl md:text-[46px] font-medium text-center mb-8 md:mb-0">
                      Beynəlxalq Əməkdaşlıqlarımız
                    </p>
                    <div className="hidden mt-[56px] gap-10 md:flex">
                      {flags.map((flag, index) => (
                        <a href={`/partners/${flag.id}`}>
                          <div key={index}>
                          <img className="h-[128px] w-[194px]" src={flag.image} alt={`Flag ${index + 1}`} />
                        </div>
                        </a>
                      ))}
                    </div>
                    <div className='block md:hidden'>
                     <Swiper
                         slidesPerView={1}
                         spaceBetween={20}
                         loop={true}
                         autoplay={{
                           delay: 4000,
                           disableOnInteraction: false,
                         }}
                         pagination={{
                           clickable: true,
                         }}
                         onSlideChange={(swiper) => {
                           setShowText(false);
                           setShowButton(false); 
                           setActiveIndex(swiper.activeIndex);
                         }}
                         modules={[Autoplay, Pagination, Navigation]}
                         className="mySwiper"
                       >
                         {flags.map((slide, index) => (
                           <SwiperSlide key={index}>
                           <a href={`/partners/${slide.id}`}>
                           <div key={index}>
                          <img  src={slide.image} alt={`Flag ${index + 1}`} />
                        </div>
                           </a>
                           </SwiperSlide>
                         ))}
                       </Swiper>
                    </div>
                  </div> */}
         
        </div>
  );
};

export default Services;