import React from 'react';
import { category1, category2, category3 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const Awards = () => {

  const { t, i18n } = useTranslation();
  return ( 
   <div className='mt-[64px]'>
     <div className="md:max-w-container max-w-containerSm mx-auto justify-between flex md:flex-row flex-col items-center gap-5">
    <div className='bg-[#388E3C] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]'>
   <p className='text-[32px] font-semibold'>{t("7 illik")}</p>
   <p className='text-[18px]'>{t("Təcrübə")}</p>
    </div>
    <div className='bg-[#FB8C00] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]'>
   <p className='text-[32px] font-semibold'>300 +</p>
   <p className='text-[18px]'>{t("Hazırlanan veb sayt")}</p>
    </div>
    <div className='bg-[#388E3C] py-[24px] px-[16px] flex w-full flex-col justify-center items-center text-white rounded-[20px] md:w-[413px]'>
   <p className='text-[32px] font-semibold'>100 %</p>
   <p className='text-[18px]'>{t("Zamanında təhvil")}</p>
    </div> 
    </div>
   </div>
  );
};

export default Awards;
