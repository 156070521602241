import React, { useEffect, useState } from "react";
import './Carousel.css';
import { useTranslation } from "react-i18next";
import { about, opportunity1, opportunity2, opportunity3, opportunity4, service1, service2, service3, service4, service5, service6, why1, why2, why3, why4, why5, why6, why7, why8, why9 } from "../../../assets/images/index";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const opportunitiesData = [
  { id: 1, image: '🌐',  title: "Vebsaytların hazırlanması", description: "Profesional və sürətli vebsayt hazırlama xidmətləri" },
  { id: 2, image: '🖥️', title: "Domen və hostinq satışı", description: "Münasib qiymətə etibarlı domen və sürətli hostinq xidmətləri" },
  { id: 3, image: '⚙️', title: "Google SEO", description: "Google SEO ilə axtarış nəticələrində zirvəyə qalxın" },
  { id: 4, image: '🛠️', title: "Vebsaytlara texniki dəstək", description: "Vebsaytlar üçün 24/7 texniki dəstək xidmətləri" },
  { id: 5, image: '📧', title: "Korporativ poçt", description: "Güvənli və professional korporativ e-poçt həlləri" },
  { id: 6, image: '💳', title: "Ödəmə sistemlərinin inteqrasiyası", description: "Müxtəlif ödəmə sistemlərinin sürətli inteqrasiyası" },
];

const Opportunities = () => {

  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: opportunity1 },
    { img: opportunity2 },
    { img: opportunity3 },
    { img: opportunity4 },
    { img: opportunity1 },
    { img: opportunity2 },
    { img: opportunity3 },
    { img: opportunity4 },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return ( 
   <> 
   <div className="max-w-container mx-auto mt-[64px] md:mt-[104px] hidden md:block">
      <div className="flex items-center justify-between">
        <p className="text-[46px] font-medium">{t("Xidmətlərimiz")}</p>
        <a href='/services'>
          <button className='h-[51px] w-[164px] rounded-[12px] bg-[#FB8C00] text-white'>{t("Hamısına bax")}</button>
        </a>
      </div>
      <div className="grid grid-cols-3 mt-10 gap-10">
        {opportunitiesData.map((item) => (
          <div key={item.id} className="rounded-[20px] py-4 w-[400px] h-[220px] px-3 shadow bg-white">
            <div className="h-[50px] w-[50px] mx-auto text-4xl"> {item.image} </div>
            <div>
              <p className="mt-[12px] text-[22px] font-medium text-center">{t(item.title)}</p>
              <p className="mt-[12px] mb-[32px] text-center">
                {item.description.length > 80 ? item.description.slice(0,80) + '...' : t(item.description)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
     <div className="max-w-containerSm mx-auto mt-[64px] md:mt-[104px] block md:hidden">
     <p className="text-3xl font-medium text-center mb-8">{t("Xidmətlərimiz")}</p>
     <Swiper
      slidesPerView={1}
      spaceBetween={20}
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false); 
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {opportunitiesData.map((slide, index) => (
        <SwiperSlide key={index}>
       <div key={slide.id} className="rounded-[20px] py-4 px-3 shadow bg-white">
       <div className="h-[50px] w-[50px] mx-auto text-4xl"> {slide.image} </div>
            <div>
              <p className="mt-[12px] text-[22px] font-medium text-center">{t(slide.title)}</p>
              <p className="mt-[12px] mb-[32px] text-center">
                {slide.description.length > 60 ? slide.description.slice(0,60) + '...' : t(slide.description)}
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    <a href='/services'>
          <button className='h-[51px] w-full md:w-[164px] rounded-[12px] mt-8 bg-[#FB8C00] text-white'>{t("Hamısına bax")}</button>
        </a>
        </div>
    </>
  );
};

export default Opportunities;

