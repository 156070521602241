import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Products from "./Pages/Products/Products";
  import ProductDetail from "./Pages/ProductDetail/ProductDetail";
  import BelowHeader from "./Components/BelowHeader/BelowHeader";
import Register from "./Pages/Register/Register";

  import Protected from "./Pages/admin/Protected/Protected";
  import Dashboard from "./Pages/admin/Dashboard/Dashboard";
  import AdminPanel from "./Pages/admin/AdminPanel/AdminPanel";
import CreateUser from "./Pages/admin/CreateUser/CreateUser";
import DashboardContact from "./Pages/admin/DashboardContact/DashboardContact";
import DashboardOffer from "./Pages/admin/DashboardOffer/DashboardOffer"
import DashboardOrders from "./Pages/admin/DashboardOrders/DashboardOrders";
import EditUser from "./Pages/admin/EditUser/EditUser";
import SubProducts from "./Pages/SubProducts/SubProducts";
import WhyUs from "./Pages/WhyUs/WhyUs";
import Services from "./Pages/Services/Services";
import Care from "./Pages/Care/Care";
import Faq from "./Pages/Faq/Faq";
import Blogs from "./Pages/Blogs/Blogs";
import BlogsDetail from "./Pages/BlogsDetail/BlogsDetail";
import PartnersDetail from "./Pages/PartnersDetail/PartnersDetail";
import { FloatingWhatsApp } from "react-floating-whatsapp";
  
  
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> 
        <Header />
        <BelowHeader/>
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Hqader Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/why-us" element={ <WhyUs/>}></Route>
          <Route path="/services" element={ <Services/>}></Route>
          <Route path="/portfolio" element={ <Care/>}></Route>
          <Route path="/faq" element={ <Faq/>}></Route>
          <Route path="/blogs" element={ <Blogs/>}></Route>
          <Route path="/blogs/:id" element={ <BlogsDetail/>}></Route>
          {/* <Route path="/about" element={ <About/>}></Route> */}
          <Route path="/contact" element={ <Contact/>}></Route>


          {/* <Route path="/admin" element={ <AdminPanel />}></Route>
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
          <Route path="/dashboard/category/:brand" element={<Protected Component={DashboardOffer} />}></Route>
        <Route path="/dashboard/create" element={<Protected Component={CreateUser} />}></Route>
            <Route path="/dashboard/orders" element={<Protected Component={DashboardOrders} />} />
            <Route path="/dashboard/contact" element={<Protected Component={DashboardContact} />} />
            <Route path="/dashboard/:id/edit" element={<Protected Component={EditUser} />} /> */}
        </Route>
      </Route>
    )
  );
   
  function App() {
  
    return (
      <div className="font-bodyFont bg-[#FBFBFB]">
          <FloatingWhatsApp phoneNumber='+994516834218' accountName="PROSAYT" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
        <RouterProvider router={router} />
      </div>
    );
  } 
  
  export default App; 
  
  
    