import React, { useState } from 'react'
import { about, about1, about2, about3, contact, facebook, globe, instagram, product1, product2, product3, product4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



const Contact = () => {

  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");
  const [clientWish, setclientWish] = useState("");



  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage, wish: clientWish };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.prosayt.az/prosayt/contact.php/", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      setclientWish('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };
  return (
      <div className="md:max-w-container max-w-containerSm mx-auto mt-[64px] md:mt-[104px]">
   <div className='flex flex-col-reverse md:flex-row justify-between'>
   <div className='mt-8 md:mt-0 hidden md:flex justify-center items-center'> 
      <img className='w-[479px] h-[412px]  md:auto rounded-3xl ' src={globe}></img>
    </div>
    <div className='md:w-[745px] md:h-[563px] md:mt-0 bg-[white] rounded-[24px] px-6 py-4 box-border'>
      <p className='text-3xl md:text-[46px] font-medium md:mt-6'>{t("Bir Sualınız Var?")} </p>
      <p className='text-3xl md:text-[46px] font-medium mb-8 md:mb-0 md:mt-6'>{t("Cavabımız Hazırdır")}</p>
      <form onSubmit={handleSubmitContact} className='md:mt-6'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
      <div>
        <input
        onChange={(event) => setclientName(event.target.value)}
        value={clientName}
        required
         placeholder={t("Ad, soyad *")} type="text" id="name" class="bg-[white] border border-neutral-200 mt-2 block w-full  rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3" />

      </div>

    
      <div>
        <input  onChange={(event) => setclientEmail(event.target.value)}
        value={clientEmail}
         required placeholder={t("Email *")} type="email" id="email" class="bg-[white] border border-neutral-200 mt-2 block w-full  rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3" ></input>
      </div>

      <div>
        <input onChange={(event) => setclientPhone(event.target.value)}
      value={clientPhone}
      required placeholder={t("Telefon *")} type="number" id="phone" class="bg-[white] border border-neutral-200 mt-2 block w-full  rounded-[5px] h-[53px] focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm pl-3 pr-3"></input>
      </div>
      </div>

   <div>
      <textarea
   id="message" rows="4" onChange={(event) => setclientWish(event.target.value)}
   value={clientWish} placeholder={t("Sizə necə kömək edə bilərik? *")} class="bg-[white] h-36 pl-3 pr-3 pt-3 block w-full border mt-6 border-[#dddddd] rounded-[5px] shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" ></textarea>
    
    </div>

    <div class="flex justify-end">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-[#FB8C00] rounded-xl justify-center items-center mt-6 gap-2.5 inline-flex text-[white]">{t("Göndər")}</button>
    </div>
  </form>
    </div>
   </div>
  </div>
  )
}

export default Contact