import React, { useState } from 'react';
import {
  about,
  about1,
  about2,
  about3,
  care1,
  care10,
  care11,
  care2,
  care3,
  care4,
  care5,
  care6,
  care7,
  care8,
  care9,
  flag1,
  flag2,
  flag3,
  flag4,
  flag5,
  flag6,
  logoAbout,
  opportunity1,
  opportunity2,
  opportunity3,
  opportunity4,
  partners1,
  partners2,
  partners3,
  partners4,
  partners5,
  product1,
  product2,
  product3,
  product4,
  sertifikat1,
  sertifikat2,
  sertifikat3,
  sertifikat4,
  sertifikat5,
  sertifikat6,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  why1,
  why2,
  why3,
  why4,
  why5,
  why6,
  why7,
  why8,
  why9
} from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const faqs = [
  {
    question: "Xidmətləriniz hansılardır?",
    answer: "Biz vebsaytların hazırlanması, domen və hostinq satışı və texniki dəstək kimi xidmətlər təqdim edirik.",
  },
  {
    question: "Layihənin tamamlanma müddəti nə qədərdir?",
    answer: "Layihənin mürəkkəbliyindən asılı olaraq müddət 1 həftədən 2 aya qədər dəyişə bilər.",
  },
  {
    question: "Xidmətlərinizin qiyməti nə qədərdir?",
    answer: "Qiymətlər layihənin tələblərinə əsasən fərqlənir, lakin büdcənizə uyğun həllər təqdim edirik.",
  },
  {
    question: "Proses rəsmi şəkildə olur?",
    answer: "Bəli, müştərilərimizlə xidmət şərtlərini dəqiqləşdirmək üçün Azərbaycan Respublikası qanunvericiliyinə uyğun şəkildə müqavilə bağlayırıq.",
  }
];

const Faq = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  return (

    <div className="md:max-w-container max-w-containerSm mx-auto mt-[24px] md:mt-[56px]">
    <p >{t("Ana səhifə")} <i className={`fas fa-chevron-right text-[black] ml-3 mr-3`}/> {t("Tez-tez verilən suallar")}</p>
    <div>
    <div className="max-w-container mx-auto mt-[24px] md:mt-[56px]">
  <div className="flex flex-col justify-center items-center">
    <p className="text-black text-3xl md:text-[46px] font-medium text-center">
      {t("Tez-tez verilən suallar")}
    </p>
  </div>
  <div className="mt-8 space-y-8">
    {faqs.map((faq, index) => (
      <div
        key={index}
        className={`rounded-[18px] overflow-hidden px-4 md:px-10 py-6 md:py-9 bg-white shadow ${
          activeIndex === index ? 'border-2 border-[#FB8C00]' : ''
        }`}
      >
        <div
          onClick={() => toggleFAQ(index)}
          className="flex justify-between items-center p-4 cursor-pointer group"
        >
          <h2
            className={`text-lg md:text-2xl font-medium w-[80%] ${
              activeIndex === index ? 'text-[#388e3c]' : 'text-[black]'
            }`}
          >
            {t(faq.question)}
          </h2>
          <div
            className={`w-[50px] h-[50px] rounded-[50%] ${
              activeIndex === index ? 'bg-[#FB8C00]' : 'bg-[white]'
            } flex justify-center items-center shadow`}
          >
            <i
              className={`fas ${
                activeIndex === index
                  ? 'fa-chevron-down text-[white]'
                  : 'fa-chevron-right text-[#FB8C00]'
              }`}
            />
          </div>
        </div>
        {activeIndex === index && (
          <div className="p-4 bg-white">
            <p className="text-gray-700 md:w-[1100px]">{t(faq.answer)}</p>
          </div>
        )}
      </div>
    ))}
  </div>
</div>

    </div>
   
  </div>
  );
};

export default Faq;