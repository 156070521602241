import React from 'react';
import { map } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const Prioritet = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="relative md:max-w-container py-[24px] md:pl-[70px] md:mt-[104px] mt-[64px] gap-5 rounded-[20px] bg-[#388E3C] max-w-containerSm mx-auto md:flex items-center justify-between">
      {/* Yazı kısmı */}
      <div className="w-full md:w-[562px] text-white md:relative md:static absolute top-0 left-0 h-full flex items-center justify-center md:justify-start px-4 md:px-0 z-10">
        <p className="text-2xl md:text-5xl font-medium md:font-semibold leading-[36px] md:leading-[72px] text-center md:text-left">
          {t("Hər biznesin vebsayta ehtiyacı var!")}
        </p>
      </div>
      
      {/* Harita görseli */}
      <div className="relative w-full md:w-[362px] h-[200px] md:h-auto">
        {/* Görselin üstüne koyu yeşil kaplama */}
        <div className="absolute inset-0 opacity-80 z-0"></div>
        <img
          className="w-full h-full object-cover z-0 mix-blend-multiply"
          src={map}
          alt="Map"
        />
      </div>
    </div>
  );
};

export default Prioritet;

